import React from 'react'
import { Outlet } from 'react-router-dom'
import { useGetMeQuery } from '../services/auth'
import { PageLoader } from '../compoments/PageLoader'

const AppPtovider = () => {
    const { data, isLoading } = useGetMeQuery()
    if (isLoading) return <div className="wrapper" style={{minHeight: '100vh'}}><PageLoader /></div> 
    return (
        <Outlet />
    )
}

export default AppPtovider
