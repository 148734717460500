import { createSlice } from '@reduxjs/toolkit'

import { alertsApi } from '../../services/alerts'
import { authApi } from '../../services/auth'
import { notificationsApi } from '../../services/notifications'
import { subscriptionsApi } from '../../services/subscriptions'
import { usersApi } from '../../services/users'
import type { RootState } from '../../store'
import { PremiumStatus } from '../../types'
import type { ISubscription, IUser } from '../../types'

interface AuthState {
    user: IUser | null
    subscription: ISubscription | null,
}
const initialState = {
    user: null,
    subscription: null,
    
} satisfies AuthState as AuthState

const alertsSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.getMe.matchRejected,
            (state, { payload }) => {
                window.localStorage.removeItem('authToken')
            },
        )
        builder
            .addMatcher(
                authApi.endpoints.getMe.matchFulfilled,
                (state, { payload }) => {
                    if (payload.data) {
                        state.user = payload.data
                    }
                    window.localStorage.removeItem('authToken')
                },
            )
            .addMatcher(
                subscriptionsApi.endpoints.getMySubscription.matchFulfilled,
                (state, { payload }) => {
                    state.subscription =
                        state.user?.premium === PremiumStatus.Subscription
                            ? payload.data.premium
                            : payload.data.trial
                },
            )
            .addMatcher(
                usersApi.endpoints.getUsers.matchFulfilled,
                (state, { payload }) => {
                    if (state.subscription) {
                        if ('searchCount' in payload) {
                            state.subscription.searchCount = payload.searchCount
                        }
                    }
                },
            )
            .addMatcher(
                (
                    action,
                ): action is { payload: { alertsCount: number | null } } =>
                    [
                        alertsApi.endpoints.createAlert.matchFulfilled,
                        alertsApi.endpoints.deleteAlert.matchFulfilled,
                        notificationsApi.endpoints.addUpdateZipAlert
                            .matchFulfilled,
                        notificationsApi.endpoints.deleteZipAlert
                            .matchFulfilled,
                        notificationsApi.endpoints.addUser.matchFulfilled,
                        notificationsApi.endpoints.deleteUser.matchFulfilled,
                    ].some((matcher) => matcher(action)),
                (state, { payload }) => {
                    if (state.subscription) {
                        if ('alertsCount' in payload) {
                            state.subscription.alertsCount = payload.alertsCount
                        }
                    }
                },
            )
    },
})

export const getUser = (state: RootState) => state.auth.user
export const getPremiumStatus = (state: RootState) => state.auth.user?.premium
export const getIsPremium = (state: RootState) =>
    !!state.auth.user &&
    [PremiumStatus.Subscription].includes(state.auth.user?.premium)

export const getIsExpired = (state: RootState) =>
    !!state.auth.user &&
    [PremiumStatus.Expired].includes(state.auth.user?.premium)

export const getIsHasFamilyAccess = (state: RootState) =>
    !!state.auth.user &&
    (state.auth.user.familyMember || state.auth.user?.familyAdmin)

export const getCurrentSubscription = (state: RootState) =>
    state.auth.subscription || null
export default alertsSlice.reducer

