import clsx from 'clsx'
import React from 'react'

import NextIcon from '../assets/img/ico-pagination--next.svg?react'
import PrevIcon from '../assets/img/ico-pagination--prev.svg?react'
import ReactPaginate from 'react-paginate'

interface PaginationProps {
    pageCount: number
    currentPage?: number
    onPageChange: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
    pageCount,
    currentPage,
    onPageChange,
}) => {
    console.log(pageCount)
    return (
        <div className="pagination">
            <ReactPaginate
                breakLabel="..."
                previousLabel={
                    <span className="ico">
                        <PrevIcon />
                    </span>
                }
                nextLabel={
                    <span className="ico">
                        <NextIcon />
                    </span>
                }
                onPageChange={(e) => onPageChange(e.selected + 1)}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination-list"
                pageClassName="pagination-item"
                breakClassName="pagination-item"
                previousClassName="btn btn--square btn--sm rounded-full btn-pagination btn-pag--nav"
                nextClassName="btn btn--square btn--sm rounded-full btn-pagination btn-pag--nav"
                previousLinkClassName="btn"
                nextLinkClassName="btn"
                pageLinkClassName="btn btn--square btn--sm rounded-full btn-pagination"
                breakLinkClassName="btn btn--square btn--sm rounded-full btn-pagination"
                activeClassName="active"
                activeLinkClassName='--active'
            />
        </div>
    )
}

export default Pagination
