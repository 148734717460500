import { createSlice } from '@reduxjs/toolkit'

import type { CrimesData, OffendersData } from '../../services/types'
import { usersApi } from '../../services/users'
import type { RootState } from '../../store'

interface IState {
    offendersAllData: OffendersData | null
    crimesData: CrimesData | null
    error: string | null
    isLoading: boolean
    searchByZip: boolean
    location: [number, number] | null
}

const initialState: IState = {
    offendersAllData: null,
    crimesData: null,
    isLoading: false,
    error: null,
    searchByZip: false,
    location: null
}

const offendersSlice = createSlice({
    name: 'offenders',
    initialState,
    reducers: {
        searchOffendersByName: (state, action) => {
            if (state.offendersAllData) {
                state.offendersAllData.offenders =
                    state.offendersAllData.offenders.filter((item: any) =>
                        item.offenderName
                            .toLowerCase()
                            .includes(action.payload.toLowerCase()),
                    )
            }
        },
        setSearchByZip: (state, action) => {
            state.searchByZip = action.payload
        },
        setCrimesData: (state, action) => {
            state.crimesData = action.payload
        },
        setLocation: (state, { payload }) => {
            state.location = payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(usersApi.endpoints.getUsers.matchPending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addMatcher(
                usersApi.endpoints.getUsers.matchFulfilled,
                (state, { payload }) => {
                    state.isLoading = false
                    if (payload.messages) {
                        state.offendersAllData = null
                    } else {
                        state.offendersAllData = payload
                    }
                },
            )
            .addMatcher(
                usersApi.endpoints.getUsers.matchRejected,
                (state, { error }) => {
                    state.offendersAllData = null
                    state.isLoading = false
                    state.error = error.message || 'An error occurred'
                },
            )
            .addMatcher(usersApi.endpoints.getCrimes.matchPending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addMatcher(
                usersApi.endpoints.getCrimes.matchFulfilled,
                (state, { payload }) => {
                    state.isLoading = false
                    state.crimesData = payload
                },
            )
            .addMatcher(
                usersApi.endpoints.getCrimes.matchRejected,
                (state, { error }) => {
                    state.crimesData  = null
                    state.isLoading = false
                    state.error = error.message || 'An error occurred'
                },
            )
    },
})

export const { searchOffendersByName, setSearchByZip, setCrimesData, setLocation } = offendersSlice.actions

export default offendersSlice.reducer
